 <template>
	<el-dialog top="1vh" title="添加客户" width="400px" :visible.sync="is_show_in_page">
		<el-form status-icon label-position="left" label-width="100px">
			<el-form-item label="客户联系电话" >
				<el-input v-model="user_tel" clearable></el-input>
			</el-form-item>
			<el-form-item label="客户联系人">
				<el-input v-model="user_name" clearable></el-input>
			</el-form-item>
			<el-form-item label="客户公司名称" >
				<el-input v-model="company_name" clearable></el-input>
			</el-form-item>
		</el-form>
		<div style="text-align:center">
			<el-button type="primary" @click="init">重置</el-button>
			<el-button type="primary" @click="sub">提交</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			old_data:Object,
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//用户编号
				user_num:'',

				//用户姓名
				user_tel:'',

				//用户姓名
				user_name:'',
				
				//客户公司名称
				company_name:'',

				//客户联系电话修改状态
				user_tel_edit_status:false,

				//客户联系人姓名修改状态
				user_name_edit_status:false,

				//客户公司名称修改状态
				company_name_edit_status:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					this.init()
				}else{
					this.is_show_in_page=false;
				}
			},
		},
		methods: {

			//是否修改结束
			is_complate(){

				//客户联系电话修改没结束
				if(this.user_tel_edit_status!=3){
					return;
				}

				//客户联系人姓名修改没结束
				if(this.user_name_edit_status!=3){
					return;
				}

				//客户公司名称修改没结束
				if(this.company_name_edit_status!=3){
					return;
				}

				//通知
				this.$emit('sub');
			},
			
			//提交
			sub(){

				if(!this.$my.check.is_tel(this.user_tel)){
					this.$my.other.msg({
						type:'warning',
						str:"缺少客户联系电话"
					});
					return;
				}

				if(!this.user_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少客户联系人"
					});
					return;
				}
				
				if(!this.company_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少客户公司名称"
					});
					return;
				}

				//修改了客户联系电话
				if(this.user_tel!=this.old_data.user_tel){

					//客户联系电话修改中
					this.user_tel_edit_status=2

					//调用接口注册
					this.$my.net.req({
						data:{
							mod:'app_user',
							ctr:'admin_edit',
							user_num:this.user_num,
							tel:this.user_tel
						},
						callback:(data)=>{

							//客户联系电话修改结束
							this.user_tel_edit_status=3

							//执行添加客户
							this.is_complate();
						}
					})
				}else{

					//客户联系电话修改结束
					this.user_tel_edit_status=3

					//执行添加客户
					this.is_complate();
				}


				//修改了客户联系人姓名
				if(this.user_name!=this.old_data.user_name){

					//客户联系人姓名修改中
					this.user_name_edit_status=2

					//调用接口注册
					this.$my.net.req({
						data:{
							mod:'app_user',
							ctr:'admin_edit',
							user_num:this.user_num,
							name:this.user_name
						},
						callback:(data)=>{

							//客户联系人姓名修改结束
							this.user_name_edit_status=3

							//执行添加客户
							this.is_complate();
						}
					})
				}else{

					//客户联系人姓名修改结束
					this.user_name_edit_status=3

					//执行添加客户
					this.is_complate();
				}


				//修改了客户公司名称
				if(this.company_name!=this.old_data.company_name){

					//客户公司名称修改中
					this.company_name_edit_status=2

					//调用接口注册
					this.$my.net.req({
						data:{
							mod:'company',
							ctr:'company_edit_admin',
							user_num:this.user_num,
							name:this.company_name
						},
						callback:(data)=>{

							//客户公司名称修改结束
							this.company_name_edit_status=3

							//执行添加客户
							this.is_complate();
						}
					})
				}else{

					//客户公司名称修改结束
					this.company_name_edit_status=3

					//执行添加客户
					this.is_complate();
				}
			},

			//重置页面
			init(){
				this.user_num=this.old_data.user_num;
				this.user_tel=this.old_data.user_tel;
				this.user_name=this.old_data.user_name;
				this.company_name=this.old_data.name;

				this.user_tel_edit_status=1;
				this.user_name_edit_status=1;
				this.company_name_edit_status=1;
			},
		}
	}
</script>