 <template>
	<el-dialog top="1vh" title="添加客户" width="400px" :visible.sync="is_show_in_page">
		<el-form status-icon label-position="left" label-width="100px">
			<el-form-item label="客户联系电话" >
				<el-input v-model="user.tel" :disabled="user.is_ser">
					<el-button slot="append" @click="user_ser">查找</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="客户联系人">
				<el-input v-model="user.name_input"></el-input>
			</el-form-item>
			<div class="shuoming" :style="{color:user.text_color}">{{user.text}}</div>

			<el-form-item label="客户公司名称" >
				<el-input v-model="company_name"></el-input>
			</el-form-item>
		</el-form>
		<div style="text-align:center">
			<el-button type="primary" @click="init">重置</el-button>
			<el-button type="primary" @click="sub">提交</el-button>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number
		},
		data() {
			return {

				//是否显示
				is_show_in_page:false,

				//客户信息
				user:{
					is_ser:false,
					is_reg:true,
					tel:'',
					name:'',
					name_input:'',
					user_num:'',
					text:'',
					text_color:""
				},
				
				//客户公司名称
				company_name:'',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
					this.is_show_in_page=true;
					
					this.init()
				}else{
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//提交客户
			customer_sub(){

				//是否添加了公司信息
				var has_creat_company=false;

				//是否添加了货主独有数据
				var has_creat_shipper_extend=false;

				//添加公司信息
				this.$my.net.req({
					data:{
						mod:'company',
						ctr:'company_add_admin',
						user_num:this.user.user_num,
						name:this.company_name
					},
					callback:(data)=>{

						//已添加
						has_creat_company=true;

						//通知
						if(has_creat_company&&has_creat_shipper_extend){
							this.$emit('sub');
						}
					}
				})

				//添加货主独有信息
				this.$my.net.req({
					data:{
						mod:'truck_other',
						ctr:'shipper_extend_creat_admin',
						user_num:this.user.user_num,
					},
					callback:(data)=>{

						//已添加
						has_creat_shipper_extend=true;

						//通知
						if(has_creat_company&&has_creat_shipper_extend){
							this.$emit('sub');
						}
					}
				})
			},
			
			//提交
			sub(){

				if(!this.user.is_ser){
					this.$my.other.msg({
						type:'warning',
						str:"请先输入手机号码, 并点击'查找'按钮"
					});
					return;
				}

				//联系人姓名必填
				if(!this.user.name_input.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少客户联系人"
					});
					return;
				}

				if(!this.company_name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"缺少客户公司名称"
					});
					return;
				}

				//需注册
				if(!this.user.is_reg){
					
					//联系电话必填
					if(!this.$my.check.is_tel(this.user.tel)){
						this.$my.other.msg({
							type:'warning',
							str:"缺少客户联系电话"
						});
						return;
					}

					//调用接口注册
					this.$my.net.req({
						data:{
							mod:'app_user',
							ctr:'admin_add',
							tel:this.user.tel,
							name:this.user.name_input
						},
						callback:(data)=>{

							//注册状态
							this.user.is_reg=true;

							//置入返回的用户编号
							this.user.user_num=data.user_num;
							this.user.name=this.user.name_input;

							//执行添加客户
							this.customer_sub();
						}
					})

				//已注册, 不需注册
				}else{

					//联系人姓名已修改了
					if(this.user.name!=this.user.name_input){
						
						//必须存在用户编号
						if(!this.user.user_num.trim()){
							this.$my.other.msg({
								type:'warning',
								str:"请先输入手机号码, 并点击'查找'按钮"
							});
							return;
						}

						//调用接口注册
						this.$my.net.req({
							data:{
								mod:'app_user',
								ctr:'admin_edit',
								user_num:this.user.user_num,
								name:this.user.name_input
							},
							callback:(data)=>{

								//已实名
								this.user.name=this.user.name_input;

								//执行添加客户
								this.customer_sub();
							}
						})

					//未修改联系人姓名
					}else{

						//执行添加客户
						this.customer_sub();
					}
				}
			},

			//重置页面
			init(){
				this.user.is_ser=false;
				this.user.is_reg=true;
				this.user.tel='';
				this.user.name='';
				this.user.name_input="";
				this.user.user_num='';
				this.user.text='';
				this.user.text_color='';
				this.company_name='';
			},

			//查找用户
			user_ser(){

				if(!this.$my.check.is_tel(this.user.tel)){
					this.$my.other.msg({
						type:'warning',
						str:"缺少客户联系电话"
					});
					return;
				}

				//调接口
				this.$my.net.req({
					data:{
						mod:'company',
						ctr:'get_none_company_user_admin',
						user_tel:this.user.tel,
					},
					callback:(data)=>{

						//锁定搜索按钮
						this.user.is_ser=true;

						//已注册
						if(data.has_creat==1){
							
							//注册状态
							this.user.is_reg=true;

							//置入用户编号
							this.user.user_num=data.user_info.user_num;

							//置入姓名
							this.user.name=data.user_info.name;
							this.user.name_input=data.user_info.name;

							//说明文字
							this.user.text="此联系人已添加, 请输入客户名称"
							this.user.text_color="#4CD964"
							
						//未注册
						}else if(data.has_creat==2){

							//打开注册按钮
							this.user.is_reg=false;	

							//结果说明
							this.user.text="此联系人未添加, 请输入联系人姓名和客户名称"
							this.user.text_color="#D73A49"
						}
					}
				});
			},
		}
	}
</script>
<style scoped>
	.shuoming{
		color: brown;
		margin: -15px 0 30px 100px;
	}
</style>