 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="110px">
			<el-form-item class="el_form_item" label="联系人电话">
				<el-input class="el_input" v-model="form.user_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="联系人姓名">
				<el-input class="el_input" v-model="form.user_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="客户名称">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="ser">查询</el-button>
				<el-button type="success" @click="open_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="添加时间">
					<template slot-scope="scope">
						<div>{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="联系人电话">
					<template slot-scope="scope">
						<div>{{scope.row.user_tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="联系人姓名">
					<template slot-scope="scope">
						<div>{{scope.row.user_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="客户名称">
					<template slot-scope="scope">
						<div>{{scope.row.name}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column fixed="right" label="操作" width="190">
					<template slot-scope="scope">
						<el-button @click="show_agent_url(scope.row)" size="mini" type="text">推广链接</el-button>
						<el-button @click="open_edit_view(scope.row)" size="mini" type="text">修改</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 添加弹出层 -->
		<customerAdd :is_show="add.is_show" @sub="add_sub"></customerAdd>

		<!-- 修改弹出层 -->
		<customerEdit :is_show="edit.is_show" :old_data="edit.old_data" @sub="edit_sub"></customerEdit>

		<!-- 推广链接弹出层 -->
		<el-dialog top="1vh" title="推广链接" width="50%" :visible.sync="agent_url.is_show">
			<div style="text-align:center">{{agent_url.url}}</div>
		</el-dialog>
	</div>
</template>

<script>
	import customerAdd from './add.vue';
	import customerEdit from './edit.vue';
	export default {
		components:{
			customerAdd,
			customerEdit
		},
		data() {
			return {
				
				//搜索条件
				form: {
					
					//客户联系电话
					user_tel:'',

					//客户联系人
					user_name:'',

					//客户名称
					name:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				
				//添加界面
				add:{

					//是否显示
					is_show:0
				},

				edit:{

					//是否显示
					is_show:0,

					//旧数据
					old_data:{}
				},

				//推广链接
				agent_url:{
					url:'',
					is_show:false,
				}
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()
		},
		methods: {

			//显示推广链接
			show_agent_url(item){

				//推广参数
				let agent_para=this.$my.cache.get('agent_para');
				if(!agent_para){
					this.$my.other.msg({
						type:'warning',
						str:"缺少推广参数"
					});
					return;
				}

				//待加密数据
				let obj={
					agent_user_num:item.user_num
				}

				//开始加密
				let json_str=JSON.stringify(obj)
				let base64_str=this.$my.base64.encode(json_str)
				let first_code=base64_str[0];
				let __init_query=encodeURIComponent(first_code+agent_para.password+base64_str.substring(1))

				//拼接url
				this.agent_url.url=agent_para.shipper_url+'?__init_query='+__init_query

				//打开
				this.agent_url.is_show=true;
			},

			//删除
			del(item){

				if(item.check_status==2){
					this.$my.other.msg({
						type:'warning',
						str:"此客户不能删除"
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击'确定'删除客户",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'company',
								ctr:'company_del_admin',
								user_num:item.user_num
							},
							callback:(data)=>{
								
								//读取页面数据
								this.get_page_data()
							}
						})
					}
				});
			},
			
			//打开客户修改界面
			open_edit_view(item){

				//置入数据
				this.edit.old_data=item

				//打开界面
				this.edit.is_show++;
			},
			edit_sub(form){
				
				//报告结果
				this.$my.other.msg({
					type:'success',
					str:"修改成功"
				});

				//关闭页面
				this.edit.is_show=0

				//读取页面数据
				this.get_page_data()
			},

			//打开客户添加界面
			open_add_view(){

				//打开界面
				this.add.is_show++;
			},
			add_sub(form){//添加
				
				//报告结果
				this.$my.other.msg({
					type:'success',
					str:"添加成功"
				});

				//关闭页面
				this.add.is_show=0
				
				//刷新页面
				this.get_page_data()
			},
			
			//搜索
			ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'company',
						ctr:'company_list_admin',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
						}
						
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 100px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>